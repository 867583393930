.content-div {
  width: 100vw;
  max-width: 100vw;
  min-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

/* .content-header {
  position: sticky;
  top: 0 
} */

.content-main {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  z-index: 1;
  height: auto;
}

.content-main-body {
  flex-grow: 9;
  overflow-y: scroll;
  height: 100%;
}

.content-main-sidebar {
  flex-grow: 1;
  overflow-y: scroll;
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  min-width: max-content;
  max-width: 20%;
  background-color: #F8F9FA;
  /* border-right: 1px solid #F8F9FA; */
}