 .sidebar {
     box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
     /* height: 100%; */
     /* width: 100%; */
     display: flex;
     flex-direction: column;
     /* overflow-y: scroll; */
     overflow-x: hidden;
     /* flex: 1; */
     padding: 1em;
 }

 #sidebar-wrapper {
     /* min-height: 100vh !important; */
     width: 100vw;
     margin-left: -1rem;
     -webkit-transition: margin .25s ease-out;
     -moz-transition: margin .25s ease-out;
     -o-transition: margin .25s ease-out;
     transition: margin .25s ease-out;
 }

 #sidebar-wrapper .sidebar-heading {
     padding: 0.875rem 1.25rem;
     font-size: 1.2rem;
 }

 #page-content-wrapper {
     min-width: 0;
     width: 100%;
 }

 .sidebar-item {
     width: 100%;
     font-size: 1.3em;
 }

 .sidebar-item a {
     display: flex;
     flex-direction: row;
     align-items: center;
     gap: 0.4em;
     color: black;
 }

 .sidebar-item-active a {
     font-weight: bold;
     color: blue;
     /* border-bottom: 1px solid black; */
 }